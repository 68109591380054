import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

export class LoginCallback extends Component {
	constructor() {
		super();

		this.state = {
			mounted: false
		}
	}

	componentDidMount() {
		localStorage.setItem('token', decodeURI(this.props.match.params.token));
		localStorage.setItem('username', decodeURI(this.props.match.params.username).replace("+", " "));

		console.log(this.props.match.params.token);
		console.log(this.props.match.params.username);

		this.setState({ mounted: true });
	}

	render() {
		return (
			<div>
				Esia login callback
				{this.state.mounted && <Redirect to="/" />}
			</div>
		)
	}
}
