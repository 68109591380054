import React, { Component } from 'react';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import decode from 'jwt-decode';
import './App.scss';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoginCallback } from './components/LoginCallback';

const loading = () => <div className="animated fadeIn pt-3 text-center">Загрузка...</div>;

// Toast
toast.configure();

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const AdminLayout = React.lazy(() => import('./containers/AdminLayout'));
const ProfileLayout = React.lazy(() => import('./containers/ProfileLayount'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
//const Register = React.lazy(() => import('./views/Pages/Register'));
//const Page404 = React.lazy(() => import('./views/Pages/Page404'));
//const Page500 = React.lazy(() => import('./views/Pages/Page500'));

// Auth check
const auth = {
	isAuthenticated: () => {
		const token = localStorage.getItem('token');
		try {
			const { exp } = decode(token);
			if (exp < Date.now().valueOf() / 1000) {
				localStorage.removeItem('token');
			}
		} catch (err) {
			console.log("Authentication error:", err);
			return false;
		}

		return true;
	}
};

// Private Route
const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) =>
	<Route {...rest} render={PrivateRender(Component)} />

const PrivateRender = (Component) => (props) =>
	auth.isAuthenticated() ?
		<Component {...props} /> :
		<Redirect to={{ pathname: "/login", state: { from: props.location } }} />

class App extends Component {
	render() {
		return (
			<BrowserRouter>
				<React.Suspense fallback={loading()}>
					<Switch>
						<Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
						<Route exact path="/auth/login-callback/:token/:username" name="Login Page" render={props => <LoginCallback {...props} />} />
						{/*<Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
						<Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
						<Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />*/}
						<PrivateRoute path="/admin" name="Администрирование" component={AdminLayout} />
						<PrivateRoute path="/account" name="Личный кабинет" component={ProfileLayout} />
						<PrivateRoute path="/" name="Главная" component={DefaultLayout} />
						
					</Switch>
				</React.Suspense>
			</BrowserRouter>
		)
	}
}

export default App;
